<!--
  经纪人 -> 科技服务匹配与撮合交易数据监控
 -->
<template>
  <div class="home page_width" v-loading="loading">
    <div class="home_header">
      <div class="header_top">
        <h1>科技服务匹配与撮合交易数据监控</h1>
        <p>今天是{{ curDate | dateFormat('YYYY年MM月DD日') }}</p>
      </div>
      <div class="header_main">
        <div class="count_list">
          <div class="icon_box">
            <i class="iconfont iconhoutaitubiao5"></i>
          </div>
          <div class="count_item">
            <h1>{{ dataSum.totalMonActive }}</h1>
            <p>本月活跃度</p>
          </div>
        </div>
        <div class="count_list">
          <div class="icon_box">
            <i class="iconfont iconhoutaitubiao5"></i>
          </div>
          <div class="count_item">
            <h1>{{ dataSum.totalReqMate }}</h1>
            <p>需求匹配次数</p>
          </div>
          <div class="count_item">
            <h1>{{ dataSum.totalReqHit }}</h1>
            <p>匹配点击次数</p>
          </div>
        </div>
        <div class="count_list">
          <div class="icon_box">
            <i class="iconfont iconhoutaitubiao5"></i>
          </div>
          <div class="count_item">
            <h1>{{ dataSum.totalSrvMate }}</h1>
            <p>服务匹配次数</p>
          </div>
          <div class="count_item">
            <h1>{{ dataSum.totalSrvHit }}</h1>
            <p>匹配点击次数</p>
          </div>
        </div>
      </div>
    </div>
    <div class="home_main">
      <div class="statistics">
        <div class="module_box chart_module">
          <div class="module_box_header">
            <p>活跃情况统计</p>
          </div>
          <div class="chart_main">
            <div class="main_header">
              <el-date-picker
                v-model="searchDate"
                :clearable="false"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                size="small"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                range-separator="~"
                @change="getActiveChartData">
              </el-date-picker>
            </div>
            <div class="chart_pane" ref="chartPane"></div>
          </div>
        </div>
        <!-- <div>
          <div class="module_box log" style="width: 320px;">
            <div class="module_box_header">
              <p>撮合记录</p>
            </div>
            <div class="log_content">
              <div class="content_list">
                <div class="log_item" v-for="item in tradeMatchList" :key="item.id">
                  <div class="item_header">
                    <user-avatar class="tw-mr-10px" :size="32" url="" />
                    <p>{{ item.username }}</p>
                    <el-tag size="mini">
                      <i class="iconfont iconjingjiren text_color_active"></i>
                      技术经纪人
                    </el-tag>
                  </div>
                  <div class="item_content">
                    <p>与<span>{{ item.targetMobile | mobileFormat }}</span>沟通</p>
                    <p>{{ item.createdAt | dateFormat }}</p>
                  </div>
                </div>
              </div>
              <div class="content_footer">
                <el-pagination
                  hide-on-single-page
                  small
                  layout="prev, pager, next"
                  @current-change="getTradeMatchData"
                  :current-page.sync="pagination.pageNum"
                  :page-size="pagination.pageSize"
                  :total="pagination.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import * as api from '@/api'
import dayjs from 'dayjs'
import geLineOptions from './line-options'
export default {
  data () {
    return {
      // 当前时间
      curDate: new Date(),
      // 页面loading
      loading: false,
      // 搜索日期
      searchDate: '',
      // 图表实例
      chart: null,
      // 数据合计
      dataSum: {
        totalMonActive: 0, // 当月活跃数
        totalReqMate: 0, // 需求匹配次数
        totalReqHit: 0, // 推荐的需求被点击次数
        totalSrvMate: 0, // 服务匹配次数
        totalSrvHit: 0 // 推荐的服务被点击次数
      },
      // 撮合记录列表
      tradeMatchList: [],
      // 撮合记录分页
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  async mounted () {
    this.loading = true
    await this.getDatasum()
    // await this.getTradeMatchData()
    await this.getActiveChartData()
    this.loading = false
  },
  methods: {
    // 获取数据合计统计
    async getDatasum () {
      await api.getDatasum().then(res => {
        if (res.data.code === 0) {
          console.log('数据合计', res.data.data)
          this.dataSum = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },
    // 获取撮合记录列表
    async getTradeMatchData () {
      await api.getTradeMatchData({
        ...this.pagination
      }).then(res => {
        if (res.data.code === 0) {
          console.log('撮合记录', res.data.data)
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tradeMatchList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },
    // 根据时间获取活跃度图表数据
    async getActiveChartData () {
      // 如果未进行筛选，则从月初至今
      if (this.searchDate.length === 0) {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        this.searchDate = [firstDay, date]
      }
      await api.getActiveChartData({
        start: dayjs(this.searchDate[0]).format('YYYY-MM-DD'),
        end: dayjs(this.searchDate[1]).format('YYYY-MM-DD')
      }).then(res => {
        if (res.data.code === 0) {
          console.log('活跃度', res.data.data)
          this.renderChart(res.data.data)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
      })
    },

    // { xData = [], yData = [] }
    renderChart (data) {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chartPane)
      }
      const options = geLineOptions(data)
      this.chart.setOption(options, true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .home {
    .home_header {
      width: 100%;
      padding-top: 10px;
      .header_top {
        padding: 20px 0;
        & > h1 {
          font-size: 24px;
          font-weight: bold;
          line-height: 40px;
          color: #000000;
        }
        & > p {
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          color: #909399;
        }
      }
      .header_main {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 30px;
        width: 100%;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        margin-bottom: 20px;
        .count_list {
          display: flex;
          align-items: center;
          height: 64px;
          .icon_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: #F2F2F2;
            border: 1px solid #C0C4CC;
            border-radius: 50%;
            margin-right: 10px;
          }
          .count_item {
            width: 100px;
            text-align: center;
            & > h1 {
              font-size: 24px;
              font-weight: bold;
              line-height: 36px;
              color: #0058DB;
              margin-bottom: 4px;
            }
            & > p {
              font-size: 12px;
              font-weight: 500;
              line-height: 24px;
              color: #606266;
            }
            &+.count_item {
              margin-left: 16px;
            }
          }
          &+.count_list {
            margin-left: 40px;
          }
        }
      }
    }

    .home_main {
      .statistics {
        display: flex;
        justify-content: space-between;
        .chart_main {
          padding: 0 10px;
          padding-bottom: 20px;
          .main_header {
            margin-bottom: 20px;
          }
          .chart_pane {
            width: 100%;
            height: 412px;
          }
        }
        .log {
          .log_content {
            .content_list {
              .log_item {
                padding-bottom: 10px;
                border-bottom: 1px dashed #DCDFE6;
                .item_header {
                  display: flex;
                  align-items: center;
                  height: 50px;
                  & > p {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 20px;
                    color: #303133;
                    margin-right: 10px;
                  }
                  i {
                    font-size: 12px;
                  }
                }
                .item_content {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  height: 30px;
                  & > p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #909399;
                    & > span {
                      margin: 0 6px;
                    }
                  }
                }
                &+.log_item {
                  margin-top: 10px;
                }
              }
            }
            .content_footer {
              display: flex;
              justify-content: center;
              padding: 20px 0;
            }
          }
        }
      }
      .module_box {
        width: 100%;
        background-color: #fff;
        padding: 0 20px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        overflow: hidden;
        .module_box_header {
          display: flex;
          align-items: center;
          height: 50px;
          margin-bottom: 10px;
          &::before {
            content: "";
            width: 3px;
            height: 20px;
            background: #3473E6;
            border-radius: 1px;
            margin-right: 10px;
          }
          & > p {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #31394C;
          }
        }
        // .module_box_main {

        // }
      }
    }
  }
</style>
